import { v1, ExtractFromAPI, Parameters } from "@netgame/openapi";

export type InitDataResponse = ExtractFromAPI<v1.paths, "/rest/app/init/", "get">;
export type InitDataResponseData = InitDataResponse["data"];

export type InitDataBanner = NonNullable<InitDataResponseData>["bannerPresetPackage"];

export type InitDataResponseNonNullable = NonNullable<InitDataResponse["data"]>;
export type InitPresetPackage = NonNullable<InitDataResponseNonNullable["popupPresetPackage"]>;
export type InitPresetPackages = NonNullable<InitDataResponseNonNullable["popupPresetPackages"]>;

export type AllGamesResponse = ExtractFromAPI<v1.paths, "/rest/page/issues/all-games/", "get">;
export type GamesPayload = NonNullable<AllGamesResponse["payload"]>;
export type Games = GamesPayload["games"];
export type GamesMenu = GamesPayload["menu"];
export type Game = NonNullable<Games>[number];

export type PromoLoginResponse = ExtractFromAPI<v1.paths, "/rest/player/promo/login/", "get">;
export type PromoLogin = NonNullable<PromoLoginResponse["data"]>;

export type LoginResponse = ExtractFromAPI<v1.paths, "/rest/login/", "post">;

export type PromoRegisterResponse = ExtractFromAPI<v1.paths, "/rest/player/promo/register/", "get">;
export type PromoRegister = NonNullable<PromoRegisterResponse["data"]>;

export type RegisterV2Response = ExtractFromAPI<v1.paths, "/rest/registerV2/", "post">;
export type RegisterV2Params = Parameters<v1.paths["/rest/registerV2/"]["post"]>;

export type PromotionsResponse = ExtractFromAPI<v1.paths, "/rest/page/promotions/", "get">;
export type PromotionsPayload = NonNullable<PromotionsResponse["payload"]>;
export type Promotions = PromotionsPayload["promotions"];
export type Promo = NonNullable<Promotions>[number];
export type PromoData = NonNullable<Promo["data"]>;
export type Promotion = {
	available?: boolean;
	type?:
		| "invite"
		| "tournament"
		| "verification"
		| "dailyWheel"
		| "inviteQualification"
		| "emailLottery"
		| "offerDeposit"
		| "offerBirthday"
		| "promoOfferConstructor"
		| "offerPeriodic"
		| "partnerReferralQualification"
		| "rushHourOffers"
		| "quest"
		| "offerDate"
		| "magicBox"
		| "piggyBank"
		| "seasons"
		| "flipTheCoin"
		| "offerPersonal"
		| "rankLeague"
		| "rankLeagueReward"
		| "chests"
		| "dailyLogin"
		| "season"
		| "prizeDrops"
		| "scratchCards"
		| "playerFreeSpin"
		| "depositStreak"
		| "offerRegular"
		| "offerWelcome";
	data?: PromoData;
};

export type InviteFriendsResponse = ExtractFromAPI<v1.paths, "/rest/page/referrals/", "get">;
export type InviteFriendsPayload = NonNullable<InviteFriendsResponse["payload"]>;
export type ReferralInfo = NonNullable<InviteFriendsPayload["referralInfo"]>;
export type RefPlayers = NonNullable<ReferralInfo["invitedFriends"]>;
export type RefPlayer = NonNullable<RefPlayers>[number];

export type HomeJackpot = NonNullable<HomePayload["jackpots"]>;
export type JackpotObj = NonNullable<HomeJackpot["TRN" | "USD"]>;
export type JackpotValue = NonNullable<JackpotObj["values"]>;
export type HomeGames = NonNullable<HomePayload["games"]>;
export type HomeIssues = NonNullable<HomeGames["issues"]>;
export type HomePopularGames = NonNullable<HomeIssues["popular-games"]>;
export type HomeHoldNLinksGames = NonNullable<HomeIssues["hold-n-link"]>;
export type HomePromotions = NonNullable<HomePayload["promotions"]>;

export type AllPlayersActivity = ExtractFromAPI<v1.paths, "/rest/players-activity/", "get">;

export type RecentWinners = NonNullable<AllPlayersActivity["data"]>;
export type RecentWinner = NonNullable<RecentWinners[number]>;

export type AllJackpotsResponse = ExtractFromAPI<v1.paths, "/rest/jackpots/history/", "get">;

export type AllGamesFiltersResponse = ExtractFromAPI<v1.paths, "/rest/get-game-filters/", "get">;
export type AllGamesFiltersData = NonNullable<AllGamesFiltersResponse["data"]>;
export type AllGamesFilters = NonNullable<AllGamesFiltersData["games"]>;
export type GameFilters = NonNullable<AllGamesFilters[number]>;

export type AvailableSoonItems = PromotionsPayload["available_soon"];
export type AvailableSoon = NonNullable<AvailableSoonItems>[number];
export type TaskType = {
	isCompleted: boolean;
};

export type ScratchCardsInfoData = ExtractFromAPI<v1.paths, "/rest/scratch-card-lottery/get/", "get">;
export type ScratchCardsTicketPlayData = ExtractFromAPI<v1.paths, "/rest/scratch-card/prize/resolve/", "post">;
export type ScratchCardsTicketData = NonNullable<ScratchCardsTicketPlayData["data"]>;
export type ScratchCardsTicketItems = NonNullable<ScratchCardsTicketData["items"]>;
export type ScratchCardsTicketItem = NonNullable<ScratchCardsTicketItems[number]>;

export type ScratchCardsTypes = "bronze" | "silver" | "gold";

export type ProfilePageResponse = Required<ExtractFromAPI<v1.paths, "/rest/page/profile/", "get">>;
export type ProfileFileResponse = ExtractFromAPI<v1.paths, "/rest/player/documents/", "get">;
export type ProfileFile = {
	status?: string;
	name?: string;
	size?: number;
	sizeError?: boolean;
	data?: File;
} & ProfileFileResponse[number];

export type AllHomeResponse = ExtractFromAPI<v1.paths, "/rest/page/home/", "get">;
export type HomePayload = NonNullable<AllHomeResponse["payload"]>;
export type HomeBanners = NonNullable<HomePayload["banners"]>;
export type HomeBanner = HomeBanners[number];

export type MoneyBoxInfoResponse = ExtractFromAPI<v1.paths, "/rest/money-box/info/", "get">;
export type MoneyBoxInfoGames = MoneyBoxInfoResponse["games"];

export type GameFiltersResponse = ExtractFromAPI<v1.paths, "/rest/get-game-filters/", "get">;
export type GameFiltersData = NonNullable<GameFiltersResponse["data"]>;
export type GameFiltersGames = GameFiltersData["games"];

export type HomeJackpots = NonNullable<HomePayload["jackpots"]>;

export type Social = {
	id: string;
	name: string;
	href: string;
	icon: string;
};

export type UserData = {
	login: string;
	password: string;
};

export type RegisterData = {
	contact: string;
	password: string;
	agree: boolean;
	timezone: string;
};

export type TournamentResponse = ExtractFromAPI<v1.paths, "/rest/tournament/", "get">;
export type TournamentsData = NonNullable<TournamentResponse["data"]>;
export type EventData<TData> = {
	type: string;
	action: string;
	data: TData;
};

export type NextTournamentsResponse = ExtractFromAPI<v1.paths, "/rest/next-tournaments/", "get">;
export type NextTournamentsData = NonNullable<NextTournamentsResponse["tournaments"]>;
export type PrevTournamentsResponse = ExtractFromAPI<v1.paths, "/rest/tournaments/history/", "get">;
export type PrevTournamentsData = NonNullable<PrevTournamentsResponse["tournaments"]>;
export type NextTournaments = NextTournamentsData[number];
export type PrevTournaments = PrevTournamentsData[number];

export type GameLastWinner = {
	type: "win" | "bigwin";
	gameMode: "TournamentPoints" | "SweepStakes";
	data: { winAmount: number; big_win_coefficient: number; id: number };
};

export type BalanceUpdate = {
	balance: string;
	bonus: Array<Record<string, unknown>>;
	bonusV2: string;
	cashUrl: string;
	cashbackAmount: number;
	compPoints: string;
	compPointsTotal: string;
	currency: string;
	entries: number;
	event: string;
	freespins_remain: number;
	leftRoleplay: number;
	roleplayLeftInPercent: number;
	roleplaying: string;
	tourPoints: number;
	winnings: string;
};

export type NotificationMessage = NonNullable<
	NonNullable<InitDataResponseData>["notificationCenter"]
>["messages"][number];

export type NotificationMessagePayload = {
	id: number;
	needSendStatusToServer: boolean;
	shouldOpenLink: boolean;
};

export type GameModes = "TournamentPoints" | "SweepStakes";

export type SocketQuestData = {
	dayIndex: number;
	taskIndex: number;
	questActiveId: number;
	name: string;
	logo: string;
	title: string;
	dateStart: string;
	dateEnd: string;
	playerData: {
		place?: number;
		completedTasks?: number;
		totalTasks?: number;
		collectedPoints?: number;
		collectedEntries?: number;
		collectedCoins?: number;
		prize?: {
			entries?: number;
			coins?: number;
		};
	};
} & Pick<QuestDayTasks[number], "executionTimeData" | "repeat" | "progress" | "prize">;

export enum EInsufficientFunds {
	TOURNAMENT_WITH_OFFER,
	SWEEPSTAKES_WITH_OFFER,
	NO_OFFER,
	SWEEPSTAKES_WITH_WINNINGS
}

export interface ConversionItem {
	key: string;
	label?: string;
	value: number;
}
export type QuestsResponse = ExtractFromAPI<v1.paths, "/rest/page/quests/", "get">;
export type QuestsWinnersResponse = ExtractFromAPI<v1.paths, "/rest/quest/get-winners/", "post">;
export type QuestPlayerData = NonNullable<QuestsResponse["payload"]>["playerData"];
export type QuestDays = NonNullable<QuestPlayerData>["days"];
export type QuestDay = NonNullable<QuestDays[number]>;
export type QuestDayTasks = QuestDays[number]["tasks"];
export type QuestDayTask = NonNullable<QuestDayTasks>[number];

export type QuestInfoResponse = ExtractFromAPI<v1.paths, "/rest/quest/", "get">;
export type QuestInfoData = NonNullable<QuestInfoResponse["data"]>;

export type BalanceResponse = ExtractFromAPI<v1.paths, "/rest/player/balance/", "get">;

export type DepositComplete = {
	action: string;
	amount: string | null;
	bannerPresetPackage: InitPresetPackage | null;
	betsAmount: string | number | null;
	bonusBanner: string | number | null;
	bonusEntries: string | number | null;
	coins: number | null;
	entries: number | null;
	isFirstDeposit: boolean;
	needBetsAmount: number;
	needIdentityVerification: boolean;
	playerData: {
		depositsCount?: number;
		needConfirmPhone: boolean;
	};
	playerDataHash: string | null;
	priorityBonus: string | null;
	showInsuranceInfo: boolean;
	type: string;
	popupPresetPackage: InitPresetPackage;
	popupPresetPackages: InitPresetPackages;
};

export type DetailInvite = {
	type: string;
	action: string;
	coins: number;
	entries: number;
};

export type PromoOfferPreset = {
	id: number;
	coins: number;
	entries: number;
	bestDeal: boolean;
	mostPopular: false;
	imageCash: string;
	imageBadge: string;
	usualPrice: number;
	forCompleteProfile: boolean;
	badgeLabel: string;
	savingsPercent: string;
};
export type OfferPreset = {
	preset: {
		id: number;
		coins: number;
		entries: number;
	};
	promoOfferPreset: PromoOfferPreset | null;
	money: number;
	status: string;
};

export type LuckySpinDataResponse = ExtractFromAPI<v1.paths, "/rest/rank-league/wheel/lucky-spin/config/", "get">;

export type LuckyWheelConfigData = {
	wheelId: string;
	wheelAvailable?: LuckySpinDataResponse["available"];
	wheelConfig: {
		auto?: LuckySpinDataResponse["auto"];
		availableDate?: LuckySpinDataResponse["availableDate"];
		endpoint?: LuckySpinDataResponse["endpoint"];
		streakCount?: LuckySpinDataResponse["streakCount"];
		streakCurrent?: LuckySpinDataResponse["streakCurrent"];
		wheels?: LuckySpinDataResponse["wheels"];
	};
};

export type PlayerResponsePost = {
	type: string;
	success: boolean;
	errors?: { type: string; message: string };
	info?: {
		profileCompleted: boolean;
		name: string;
		surname: string;
		city: string;
		address: string;
		address2: string;
		zip: string;
		state: string;
		email: string;
		birthDay: string;
		birthMonth: string;
		birthYear: string;
		phone: string;
		easyProfileCompleted: boolean;
		easyProfileModuleEnabled: boolean;
		uncompletedProfileFields: string[];
	};
};

export type WheelSectorData = {
	currency?: string;
	sectors?: { entries?: number; coins?: number }[][];
};

export type WheelWinSector = { entries?: number; coins?: number };

export type WheelSector = Record<string, WheelSectorData>;

export type SocketSeasonData = {
	completedLevelIndex?: number;
	levelProgressPercents?: number;
	currentLevel?: number;
	entries?: number;
	coins?: number;
};
export type CashHistoryOutResponse = ExtractFromAPI<v1.paths, "/rest/cash/history/out/", "get">;
export type CashHistoryOutData = NonNullable<CashHistoryOutResponse["data"]>;
export type CashHistoryOutItems = NonNullable<CashHistoryOutData["items"]>;
export type CashHistoryOutItem = NonNullable<CashHistoryOutItems[number]>;

export type Column = {
	headerName: string;
	field: string;
	class?: string;
	width: number;
};

export type Pagination = {
	current: number | null;
	pages: number | null;
};

export type Row = {
	[key: string]: string | number;
};

export type DepositStreakInfoData = ExtractFromAPI<v1.paths, "/rest/deposit-streak/info/", "get">;
export type DepositStreakInfo = DepositStreakInfoData & { progress: boolean[] } & { canWheel?: boolean };

export type PrizeDropsLevel = "1" | "2" | "3";
export type FreeSpins = { gameId: number; spinsRemain: number; spinsTotal: number };
